.success {
  @apply bg-success-green;
  @apply text-white;
  @apply rounded-full;
  @apply p-4;
  @apply mb-4;
  height: 74px !important;
  width: 74px !important;
}

.error {
  @apply bg-functional-red;
  @apply text-white;
  @apply rounded-full;
  @apply p-4;
  @apply mb-4;
  height: 74px !important;
  width: 74px !important;
}

.warning {
  @apply bg-[#F6F9FC];
  @apply text-functional-red;
  @apply rounded-full;
  @apply p-4;
  @apply mb-4;
  height: 74px !important;
  width: 74px !important;
}
